'use client'

import {useState} from 'react'
import clsx from 'clsx'

import {Button} from './Button'
import {Container} from './Container'
import {Logomark} from './Logo'
import {useTranslation} from "react-i18next";
import {Radio, RadioGroup} from "@headlessui/react";

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
            <path
                d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                fill="currentColor"
            />
            <circle
                cx="12"
                cy="12"
                r="8.25"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function Plan(props) {
    const {
        name,
        price,
        description,
        button,
        features,
        activePeriod,
        logomarkClassName,
        featured = false,
    } = props;
    const {t} = useTranslation();


    console.log(props);
    return (
        <section
            className={clsx(
                'flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5',
                featured ? 'order-first bg-gray-900 lg:order-none' : 'bg-white',
            )}
        >
            <h3
                className={clsx(
                    'flex items-center text-sm font-semibold',
                    featured ? 'text-white' : 'text-gray-900',
                )}
            >
                <Logomark className={clsx('h-6 w-6 flex-none', logomarkClassName)}/>
                <span className="ml-4">{name}</span>
            </h3>
            <p
                className={clsx(
                    'relative mt-5 flex text-3xl tracking-tight',
                    featured ? 'text-white' : 'text-gray-900',
                )}
            >
                {price.Monthly === price.Annually ? (
                    price.Monthly
                ) : (
                    <>
            <span
                aria-hidden={activePeriod === t(globalThis.AUDIENCE + '.homepage.pricing.other.annually')}
                className={clsx(
                    'transition duration-300',
                    activePeriod === t(globalThis.AUDIENCE + '.homepage.pricing.other.annually') &&
                    'pointer-events-none translate-x-6 select-none opacity-0',
                )}
            >
              {price.Monthly} {price.Monthly !== "Free" && <span className="text-sm">{t(globalThis.AUDIENCE + '.homepage.pricing.other.perMonth')}</span>}
            </span>
                        <span
                            aria-hidden={activePeriod === t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly')}
                            className={clsx(
                                'absolute left-0 top-0 transition duration-300',
                                activePeriod === t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly') &&
                                'pointer-events-none -translate-x-6 select-none opacity-0',
                            )}
                        >
              {price.Annually} {price.Annually !== "Free" && <span className="text-sm">{t(globalThis.AUDIENCE + '.homepage.pricing.other.perMonth')}</span>}
            </span>
                    </>
                )}
            </p>
            {/*<p*/}
            {/*    className={clsx(*/}
            {/*        'mt-3 text-sm',*/}
            {/*        featured ? 'text-gray-300' : 'text-gray-700',*/}
            {/*    )}*/}
            {/*>*/}
            {/*    {description}*/}
            {/*</p>*/}
            <div className="order-last mt-6">
                <ul
                    role="list"
                    className={clsx(
                        '-my-2 divide-y text-sm',
                        featured
                            ? 'divide-gray-800 text-gray-300'
                            : 'divide-gray-200 text-gray-700',
                    )}
                >
                    {features.map((feature, count) => (
                        <li key={feature} className="flex py-2">
                                <CheckIcon
                                    className={clsx(
                                        'h-6 w-6 flex-none',
                                        featured ? 'text-white' : 'text-primary',
                                    )}
                                />
                            <div>
                                <p className="ml-4">{feature.name}
                                    {count > 5 &&
                                        <>

                                    <span
                                        className="ml-2 inline-flex items-center rounded-md bg-green-50 px-1 py-0.5 mr-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Coming Soon
                                      </span>

                                        </>}


                                </p>
                                <p className="ml-4 mt-1 text-xs text-gray-500">{feature.desc}</p>

                            </div>

                        </li>
                    ))}
                </ul>
            </div>
            <Button
                href="https://app.pocketcfo.io/onboarding"
                color={featured ? 'cyan' : 'gray'}
                className="mt-6"
                aria-label={`Get started with the ${name} plan for ${price}`}
            >
                {button.label}
            </Button>
        </section>
    )
}

export function Pricing() {
    const {t} = useTranslation();
    let [activePeriod, setActivePeriod] = useState(t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly'))
    console.log(t(globalThis.AUDIENCE + '.homepage.pricing.plans', {returnObjects: true}))
    return (
        <section
            id="pricing"
            aria-labelledby="pricing-title"
            className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
        >
            <Container>
                <div className="mx-auto max-w-2xl text-center">
                    <h2
                        id="pricing-title"
                        className="text-3xl font-medium tracking-tight text-gray-900"
                    >
                        {t(globalThis.AUDIENCE + '.homepage.pricing.header')}
                    </h2>
                </div>

                <div className="mt-8 flex justify-center">
                    <div className="relative">
                        <RadioGroup
                            value={activePeriod}
                            onChange={setActivePeriod}
                            className="grid grid-cols-2"
                        >
                            {[t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly'), t(globalThis.AUDIENCE + '.homepage.pricing.other.annually')].map((period) => (
                                <Radio
                                    key={period}
                                    value={period}
                                    className={clsx(
                                        'cursor-pointer border border-gray-300 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-sm text-gray-700 outline-2 outline-offset-2 transition-colors hover:border-gray-400 text-center',
                                        period === t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly')
                                            ? 'rounded-l-lg'
                                            : '-ml-px rounded-r-lg',
                                    )}
                                >
                                    {period}
                                    {period === t(globalThis.AUDIENCE + '.homepage.pricing.other.annually') &&
                                        <span
                                            className="ml-2 inline-flex items-center rounded-md bg-green-50 px-1 py-0.5 mr-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {t(globalThis.AUDIENCE + '.homepage.pricing.other.save')} 25%
                                      </span>
                                    }

                                </Radio>
                            ))}
                        </RadioGroup>
                        <div
                            aria-hidden="true"
                            className={clsx(
                                'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg bg-primary transition-all duration-300',
                                activePeriod === t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly')
                                    ? '[clip-path:inset(0_50%_0_0)]'
                                    : '[clip-path:inset(0_0_0_calc(50%-1px))]',
                            )}
                        >
                            {[t(globalThis.AUDIENCE + '.homepage.pricing.other.monthly'), t(globalThis.AUDIENCE + '.homepage.pricing.other.annually')].map((period) => (
                                <div
                                    key={period}
                                    className={clsx(
                                        'py-2 text-center text-sm font-semibold text-white',
                                        period === t(globalThis.AUDIENCE + '.homepage.pricing.other.annually') && '-ml-px',
                                    )}
                                >
                                    {period}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-4xl lg:grid-cols-2">
                    {safeArray(t(globalThis.AUDIENCE + '.homepage.pricing.plans', {returnObjects: true})).map((plan) => (
                        <Plan key={plan.name} {...plan} activePeriod={activePeriod}/>
                    ))}
                </div>
            </Container>
        </section>
    )
}


function safeArray(unsafeArray) {
    if (Array.isArray(unsafeArray))
        return unsafeArray

    return []
}
