'use client'

import {
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
} from '@headlessui/react'
import {AnimatePresence, motion} from 'framer-motion'

import {Button} from './Button'
import {Container} from './Container'
import {Logo} from './Logo'
import {NavLinks} from './NavLinks'
import LanguageSwitch from "./LanguageSwitch";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {AudienceSelector} from "./AudienceSelector";

function MenuIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M5 6h14M5 18h14M5 12h14"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function ChevronUpIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M17 14l-5-5-5 5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function MobileNavLink(props) {
    return (
        <PopoverButton
            as={"a"}
            className="block text-base leading-7 tracking-tight text-gray-700"
            {...props}
        />
    )
}

export function Header() {
    const {t} = useTranslation();

    return (
        <header>
            <nav>

                <Container className="relative z-50 flex justify-between pb-8 pt-8">
                    <div className="relative z-10 flex items-center gap-4">
                        <a href="/" aria-label="Home">
                            <Logo className="h-10 w-auto"/>
                        </a>
                        <div className="hidden xl:flex items-center justify-between w-full lg:gap-8">
                            <NavLinks/>
                            <AudienceSelector/>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">

                        <Popover className="xl:hidden">
                            {({open}) => (
                                <>
                                    <PopoverButton
                                        className="relative z-10 -m-2 flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                                        aria-label="Toggle site navigation"
                                    >
                                        {({open}) =>
                                            open ? (
                                                <ChevronUpIcon className="h-7 w-7"/>
                                            ) : (
                                                <MenuIcon className="h-7 w-7"/>
                                            )
                                        }
                                    </PopoverButton>
                                    <AnimatePresence initial={false}>
                                        {open && (
                                            <>
                                                <PopoverOverlay
                                                    static
                                                    as={motion.div}
                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}
                                                    className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                                />
                                                <PopoverPanel
                                                    static
                                                    as={motion.div}
                                                    initial={{opacity: 0, y: -32}}
                                                    animate={{opacity: 1, y: 0}}
                                                    exit={{
                                                        opacity: 0,
                                                        y: -32,
                                                        transition: {duration: 0.2},
                                                    }}
                                                    className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                                >
                                                    <div className="space-y-4">
                                                        <MobileNavLink
                                                            href="/#features">{t(globalThis.AUDIENCE + '.navbar.links.features')}</MobileNavLink>
                                                        <MobileNavLink
                                                            href="/#pricing">{t(globalThis.AUDIENCE + '.navbar.links.pricing')}</MobileNavLink>
                                                        <MobileNavLink
                                                            href="/#faqs">{t(globalThis.AUDIENCE + '.navbar.links.faqs')}</MobileNavLink>
                                                        <hr/>
                                                        {globalThis.AUDIENCE !== "forEntrepreneurs" &&
                                                            <MobileNavLink
                                                                href="/">{t(globalThis.AUDIENCE + '.navbar.links.entrepreneurs')}</MobileNavLink>}

                                                        {globalThis.AUDIENCE !== "forFinanceProfessionals" &&
                                                            <MobileNavLink
                                                                href="/?for=finance-professionals">{t(globalThis.AUDIENCE + '.navbar.links.financeProfessionals')}</MobileNavLink>}

                                                        {globalThis.AUDIENCE !== "forInvestorsAndLenders" &&
                                                            <MobileNavLink
                                                                href="/?for=investors-lenders">{t(globalThis.AUDIENCE + '.navbar.links.investors')}</MobileNavLink>}
                                                    </div>
                                                    <div className="mt-8 flex flex-col gap-4">
                                                        <Button href="https://app.pocketcfo.io" variant="outline">
                                                            Log in
                                                        </Button>
                                                    </div>
                                                </PopoverPanel>
                                            </>
                                        )}
                                    </AnimatePresence>
                                </>
                            )}
                        </Popover>
                        <LanguageSwitch/>
                        {/*<Button href="#pricing" className="hidden xl:block">*/}
                        {/*    {t(globalThis.AUDIENCE + '.general.try_it_CTA')}*/}
                        {/*</Button>*/}
                        <Button href="https://app.pocketcfo.io" variant="outline" className="ml-auto hidden lg:block !py-2">
                            Log in
                        </Button>

                    </div>
                </Container>
            </nav>
        </header>
    )
}
